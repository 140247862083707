<template>
  <div class="home">
    <h1>Download Area</h1>
    <p>Download the latest Windows Client</p>
    <div class="download-section">
      <div class="file-info">
        <h2>SaveTogether.exe</h2>
        <p>Version: 1.0.4</p>
        <p>Size: 742 KB</p>
      </div>
      <button class="download-button" @click="downloadFile">Download</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyHome',
  methods: {
    downloadFile() {
      const fileUrl = 'https://playtogetherpublic.blob.core.windows.net/setup/SaveTogether.exe';
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = 'SaveTogether.exe';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
.home {
  text-align: center;
  padding: 20px;
}
.download-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 15px;
}
.file-info {
  color: #66c0f4;
}
.download-button {
  background-color: #5c7e10;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.download-button:hover {
  background-color: #4a6410;
}
</style>
